// V1.0 Has updated Stripe API KeyboardEvent.

let orinattok = {
  // SERVER_URL: "http://localhost:5000/", //This is used for lambda function call made by orinattok
  SERVER_URL: "https://3duid6sf06.execute-api.us-east-1.amazonaws.com/latest", //This is used for lambda function call made by orinattok
  PRODUCTION: false,
  STRIPE_PUBLIC_KEY: "pk_test_HX5XVlQXcZF6WfTbaus1zr45", // My stripe key
  FIREBASE_API_KEY: "AIzaSyDvRpBBaRr3mOM1RprcGuZV8uqfDk7NyKA",
  FIREBASE_AUTH: "test.firebaseapp.com",
  SEGMENTKEY: "Fsd4kuiExAClyqfUMzlbLXnDsqFVBqON",
  Build: "O ",
  V: "1",
};

let roost = {
  SERVER_URL: "https://3duid6sf06.execute-api.us-east-1.amazonaws.com/latest", //Dev //This is used for lambda function call made by orinattok
  PRODUCTION: false,
  STRIPE_PUBLIC_KEY: "pk_test_HX5XVlQXcZF6WfTbaus1zr45", // Created 7/31/2018
  FIREBASE_API_KEY: "AIzaSyCnRQWiCaHmmN9pIQqR9GrXC3VF9fNMadw",
  // FIREBASE_AUTH: "inception-958d2.firebaseapp.com",
  FIREBASE_AUTH: "auth.roost.brightmind.com",
  SEGMENTKEY: "Fsd4kuiExAClyqfUMzlbLXnDsqFVBqON", //Segment Webapp Roost
  Build: "R ",
  V: "7.0.13",
};

let genesis = {
  SERVER_URL: "https://nksgsdatv4.execute-api.us-east-1.amazonaws.com/latest", //Genesis //This is used for lambda function call made by orinattok
  PRODUCTION: true,
  STRIPE_PUBLIC_KEY: "pk_live_P3yDwJi2H7UCiwqE3N0U1QJU", //Created 7/31/2020
  FIREBASE_API_KEY: "AIzaSyD7as8D_BBhSgssqFeY9Ydh8ICYipxPk0Q",
  FIREBASE_AUTH: "auth.genesis.brightmind.com",
  SEGMENTKEY: "cUQJC4JxqIn5vHseMu6p730k4aXJBD3C", //Segment Webapp Genesis
  Build: "G ",
  V: "7.1.0",
};

export const GlobalKeys = Object.freeze(genesis);
