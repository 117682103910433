import React, { useState } from "react";
import StripeCheckout from "react-stripe-checkout";
import axios from "axios";
//Local
import { GlobalKeys } from "./globals";
//UI
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

var Analytics = require("analytics-node");
var analytics = new Analytics(GlobalKeys.SEGMENTKEY, {
  flushAt: 1,
});

export default function Pricing(props) {
  // Declare a new state variable, which we'll call "count"
  const [loading, setLoading] = useState(false);
  let email = props.email;
  let userId = props.userId;
  let planData = props.planData;
  let couponData = props.couponData;
  let planId = props.planId;
  let couponId = props.couponId;
  let isPlanIdPresent = props.isPlanIdPresent;
  let isCouponIdPresent = props.isCouponIdPresent;
  let isDiscountAvailable = false;
  let mainAmount = 0;
  let discountedAmount = 0; // Discount amount

  //There should be a valid plan and coupon
  if (isCouponIdPresent && isPlanIdPresent) {
    let isAmountOff = false; // Coupon can amount off or Percent off
    if (couponData.amount_off !== null) {
      isAmountOff = true; // The coupon is Percent off, Not amount off
    }

    if (isAmountOff) {
      //That means coupon is amount off
      discountedAmount = planData.amount - couponData.amount_off;
      discountedAmount = discountedAmount / 100;
      isDiscountAvailable = true;
    } else {
      //That means coupon is percent off
      discountedAmount = planData.amount - (couponData.percent_off / 100) * planData.amount;
      discountedAmount = discountedAmount / 100;
      isDiscountAvailable = true;
    }
  }

  if (isDiscountAvailable) {
    mainAmount = discountedAmount * 100;
  } else {
    mainAmount = planData.amount;
  }

  const tiers = [
    {
      nickname: planData.nickname,
      subheader: isDiscountAvailable ? couponData.name : "",
      amount: planData.amount / 100,
      interval: planData.interval,
      isDiscountAvailable: isDiscountAvailable,
      description: ["", ""],
      buttonText: "Buy Subscription",
      buttonVariant: "contained",
    },
  ];

  const charge = (user) => {
    let options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // console.log("charge options: ", options);
    // console.log("charge body: ", user);
    setLoading(true);
    axios
      .post(GlobalKeys.SERVER_URL + "/charge", user, options) // Calling orinattok lambda function to complete the stripe payment and update the dynamoDb table
      .then((data) => {
        console.log("charge data: ", data);
        if (data.data.success) {
          console.log("success: ", data.data.msg);
          console.log("success data: ", data.data);

          setLoading(false);
          console.log("User coupon Top: ", user.couponId);
          if (user.couponId) {
            console.log("User coupon: ", user.couponId);
            // analytics.track({
            //   userId: user.userId,
            //   event: "coupon_redeemed",
            //   properties: {
            //     coupon: user.couponId,
            //   },
            // });

            // analytics.identify({
            //   userId: user.userId,
            //   traits: {
            //     coupon: user.couponId,
            //   },
            // });
          }

          window.location.reload();
        } else if (!data.data.success) {
          setLoading(false);
          alert("Contact Customer Care With Error Code : " + data.data.code);
        }
      })
      .catch((error) => {
        console.log("error: From charge ", error);
      });
  };

  const makePayment = (token) => {
    let body;
    if (isDiscountAvailable) {
      body = {
        token: token,
        planId: planId,
        couponId: couponId,
        userId: userId,
        isDiscountAvailable: isDiscountAvailable,
      };
    } else {
      body = {
        token: token,
        planId: planId,
        userId: userId,
        isDiscountAvailable: isDiscountAvailable,
      };
    }

    charge(body);
  };
  let planCard;
  discountedAmount = discountedAmount.toFixed(2);
  if (isDiscountAvailable) {
    planCard = (
      <Box width="100%" mx="auto">
        <Grid container justify="center" direction="column" alignItems="center">
          <strike>
            <Typography component="h2" variant="h6" color="textPrimary" display="inline">
              ${tiers[0].amount} / {tiers[0].interval}
            </Typography>
          </strike>
          <Typography component="h2" variant="h5" color="textPrimary" display="inline">
            ${discountedAmount} / {tiers[0].interval}
          </Typography>
        </Grid>
      </Box>
    );
  } else {
    planCard = (
      <Grid container justify="center">
        <Typography component="h2" variant="h5" color="textPrimary" display="inline">
          ${tiers[0].amount} / {tiers[0].interval}
        </Typography>
      </Grid>
    );
  }

  return (
    <React.Fragment>
      {loading ? (
        <Grid container justify="center" direction="column" alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        <Grid item key={tiers[0].nickname} container direction="column" justify="center" alignItems="center">
          <Container maxWidth="xs" component="main">
            <Card>
              <CardHeader title={tiers[0].nickname} subheader={tiers[0].subheader} titleTypographyProps={{ align: "center" }} subheaderTypographyProps={{ align: "center" }} action={tiers[0].isDiscountAvailable === true ? null : null} />
              <CardContent>
                {planCard}
                <ul>
                  {/* {tier.description.map((line) => (
                          <Typography
                            component="li"
                            variant="subtitle1"
                            align="center"
                            key={line}
                          >
                            {line}
                          </Typography>
                        ))} */}
                </ul>
              </CardContent>
              <CardActions>
                <Box mx="auto">
                  <StripeCheckout stripeKey={GlobalKeys.STRIPE_PUBLIC_KEY} token={makePayment} name={tiers[0].buttonText} amount={mainAmount} align="center" email={email}>
                    <Button variant={tiers[0].buttonVariant} color="primary">
                      <Typography variant="button">{tiers[0].buttonText}</Typography>
                    </Button>
                  </StripeCheckout>
                </Box>
              </CardActions>
            </Card>
          </Container>
        </Grid>
      )}
    </React.Fragment>
  );
}
