import React, { Component } from "react";
import firebase from "firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import axios from "axios";
//Local
import { GlobalKeys } from "./globals";
import Subscription from "./Subscription";
//UI
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

//Initialization
firebase.initializeApp({
  apiKey: GlobalKeys.FIREBASE_API_KEY,
  authDomain: GlobalKeys.FIREBASE_AUTH,
});

var Analytics = require("analytics-node");
var analytics = new Analytics(GlobalKeys.SEGMENTKEY, {
  flushAt: 1,
});

let planId = null;
let priceId = null;
let couponId = null;
let couponData = null;
let planData = null;
//For promotion
let type = null;
let duration = null;
let promoName = null;
let returnSlug = null;

//Version
let version = GlobalKeys.Build + " " + GlobalKeys.V;
console.log("Version: " + version);
//New user
let isNewUser;

//Subscription
let userSubscriptionData = null;
let userData = null;
class App extends Component {
  state = {
    isSignedIn: false,
    isPlanIdPresent: false,
    isCouponIdPresent: false,
    isAlreadySubscriber: true,
    checkedSubscription: false,
    isLoading: false,
    isPromoted: false,
    isLifeTime: false,
    isBuyLifeTime: false,
    nameFL: "",
  };

  uiConfig = {
    credentialHelper: "none",
    signInFlow: "popup",
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID, "apple.com", firebase.auth.EmailAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        isNewUser = authResult.additionalUserInfo.isNewUser;
        console.log("isNewUser: ", isNewUser);
        let allProviders = "";
        for (let index = 0; index < firebase.auth().currentUser.providerData.length; index++) {
          allProviders = allProviders + firebase.auth().currentUser.providerData[index].providerId + ",";
        }

        if (allProviders.charAt(allProviders.length - 1) === ",") {
          allProviders = allProviders.slice(0, -1);
        }
        allProviders = allProviders.replaceAll(".com", "");
        allProviders = allProviders.replace("password", "email");
        allProviders = allProviders.replace("facebook", "");
        console.log("🚀 ~ file: App.js ~ line 94 ~ App ~ allProviders", allProviders);
        let date = new Date().toISOString() + "";

        if (isNewUser) {
          // analytics.track({
          //   userId: "firebase:" + firebase.auth().currentUser.uid,
          //   event: "af_complete_registration",
          // });

          // analytics.identify({
          //   userId: "firebase:" + firebase.auth().currentUser.uid,
          //   traits: {
          //     name: firebase.auth().currentUser.displayName,
          //     email: firebase.auth().currentUser.email,
          //   },
          // });

          analytics.track({
            userId: "firebase:" + firebase.auth().currentUser.uid,
            event: "sign_up",
          });
          analytics.identify({
            userId: "firebase:" + firebase.auth().currentUser.uid,
            traits: {
              name: firebase.auth().currentUser.displayName,
              email: firebase.auth().currentUser.email,
              createdAt: date,
              sign_in_method: allProviders,
            },
          });
        } else {
          analytics.track({
            userId: "firebase:" + firebase.auth().currentUser.uid,
            event: "sign_in",
          });
          analytics.identify({
            userId: "firebase:" + firebase.auth().currentUser.uid,
            traits: {
              name: firebase.auth().currentUser.displayName,
              email: firebase.auth().currentUser.email,
              sign_in_method: allProviders,
            },
          });
        }
        return false;
      },
    },
  };
  //Get the plan data from Orinattok lambda
  plan(planId) {
    let body = {
      planId: planId,
    };
    axios
      .post(GlobalKeys.SERVER_URL + "/pricing", body)
      .then((res) => {
        planData = res.data;
        console.log("res: Plan data", planData);
        if (planData.success) {
          this.setState({ isPlanIdPresent: planData.active });
        } else {
          alert("Contact Customer Care With Error Code : " + planData.code);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  //Get the coupon data from Orinattok lambda
  coupon(couponId) {
    let body = {
      couponId: couponId,
    };
    axios
      .post(GlobalKeys.SERVER_URL + "/coupon", body)
      .then((res) => {
        couponData = res.data;
        console.log("res: Coupon data", couponData.success);
        if (couponData.success) {
          this.setState({ isCouponIdPresent: couponData.valid });
        } else {
          alert("Contact Customer Care With Error Code : " + couponData.code);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  //When the user logged get data from dynamoDB table
  loginUser(user) {
    //Start loading
    this.setState({
      isLoading: true,
    });
    this.getUrlArgs();

    axios
      .post(GlobalKeys.SERVER_URL + "/profile", user)
      .then(async (data) => {
        console.log("data: loginuser", data);
        if (data.data.success) {
          userSubscriptionData = data.data;
          console.log("userSubscriptionData : ", userSubscriptionData);

          // const url = "https://api.revenuecat.com/v1/subscribers/firebase:" + firebase.auth().currentUser.uid;
          // let rvc;
          // try {
          //   rvc = await axios({
          //     method: "GET",
          //     url: url,
          //     headers: {
          //       Accept: "application/json",
          //       "X-Platform": "web",
          //       "Content-Type": "application/json",
          //       Authorization: "Bearer " + GlobalKeys.REVENUECAT_KEY,
          //     },
          //   });
          //   console.log("Axis Revenuecat rvc: ", rvc);
          // } catch (error) {
          //   console.log("Axis Revenuecat error: ", error);
          // }

          this.setState({
            checkedSubscription: true,
            isAlreadySubscriber: userSubscriptionData.isAlreadyValidSubscriber,
            isLoading: false,
            isLifeTime: userSubscriptionData.isLifeTime,
          });

          console.log("userSubscriptionData this.state.isLifeTime: ", this.state.isLifeTime);
        } else {
          this.setState({
            isLoading: false,
            checkedSubscription: false,
          });
          alert("Contact Customer Care With Error Code : " + data.data.code);
        }
      })
      .catch((error) => {
        console.log("error: loginUser", error);
      });
  }

  //For New User
  newUser(user) {
    this.setState({
      isLoading: true,
    });
    this.setState({
      isLoading: false,
      nameFL: firebase.auth().currentUser.displayName,
    });

    // axios
    //   .post(GlobalKeys.SERVER_URL + "/new", user)
    //   .then((data) => {
    //     console.log("data: new user", data);
    //     if (data.data.success) {
    //       console.log("new user success: ", data.data.success);
    //       this.setState({
    //         isLoading: false,
    //         nameFL: data.data.userAttributes.Item.firstName,
    //       });
    //     } else {
    //       console.log("new user table create error: ", data);
    //       this.setState({
    //         isLoading: false,
    //       });
    //       alert("Contact Customer Care With Error Code : " + data.data.code);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("error: new user call", error);
    //   });
  }

  async getCheckOutLink(user) {
    try {
      let checkOutData = await axios.get(GlobalKeys.SERVER_URL + "/create_checkout?userId=" + user.userId + "&priceId=" + priceId + "&email=" + firebase.auth().currentUser.email + "&returnSlug=" + returnSlug);
      console.log("checkOutData:", checkOutData.data.url);
      window.top.location.href = checkOutData.data.url;
    } catch (error) {
      alert("Error getting checkout : " + error);
    }
  }

  //For Old User
  oldUser(user) {
    this.setState({
      isLoading: true,
    });
    axios
      .post(GlobalKeys.SERVER_URL + "/old", user)
      .then((data) => {
        console.log("data: old user", data);
        if (data.data.success) {
          console.log("old user success: ", data.data.success);
          this.setState({
            isLoading: false,
            nameFL: data.data.userAttributes.Item.firstName,
          });
        } else {
          console.log("old user table get error: ", data);
          this.setState({
            isLoading: false,
          });
          alert("Contact Customer Care With Error Code : " + data.data.code);
        }
      })
      .catch((error) => {
        console.log("error: old call user", error);
      });
  }

  //For Promotional Call
  forPromotion(user) {
    console.log("For promotion");
    this.setState({
      isLoading: true,
    });
    axios
      .post(GlobalKeys.SERVER_URL + "/promo", user)
      .then((data) => {
        if (data.data.success) {
          console.log("data.data: ", data.data);
          this.setState({
            isLoading: false,
          });
          window.location.reload();
        } else {
          console.log("data.data: ", data.data);
          this.setState({
            isLoading: false,
          });
          alert("Contact Customer Care With Error Code : " + data.data.code);
          // window.location.reload();
        }
      })
      .catch((error) => {
        console.log("error: promotion call", error);
      });
  }

  getUrlArgs = () => {
    //Taking the planId and couponId from URL
    let search = window.location.search;
    let params = new URLSearchParams(search);
    planId = params.get("planId");
    priceId = params.get("priceId");
    couponId = params.get("couponId");
    type = params.get("type");
    duration = params.get("duration");
    promoName = params.get("promo");
    returnSlug = params.get("returnSlug");

    console.log("planId and couponId", planId, couponId);
    if (planId !== null) {
      if (couponId !== null) {
        //There is a coupon on the URI
        this.coupon(couponId);
        this.plan(planId);
      } else {
        //There is no coupon on the URI
        this.plan(planId);
      }
    } else if (type === "promotion" && duration !== null && promoName !== null && priceId === null) {
      userData.duration = duration;
      userData.promoName = promoName;
      console.log("userData: ", userData);
      this.setState({
        isPromoted: true,
      });
    } else if (type === "lifetime" && duration === null && promoName === null && priceId !== null) {
      this.setState({
        isBuyLifeTime: true,
      });
    }
  };

  componentDidMount = async () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (!GlobalKeys.PRODUCTION) {
          user
            .getIdToken(true)
            .then(function (idToken) {
              console.log("App -> componentDidMount -> Barrier idToken", idToken);
              console.log("App -> componentDidMount -> user.uid", user.uid);
            })
            .catch(function (error) {
              console.log("App -> componentDidMount -> error", error);
            });
        }
        userData = {
          userId: "firebase:" + firebase.auth().currentUser.uid,
          email: firebase.auth().currentUser.email,
          firstName: firebase.auth().currentUser.displayName,
          duration: null,
          promoName: null,
        };
        this.loginUser(userData);
        if (isNewUser) {
          this.newUser(userData);
        } else {
          this.oldUser(userData);
        }
      } else {
        this.setState({
          isPlanIdPresent: false,
          isCouponIdPresent: false,
          isAlreadySubscriber: false,
          checkedSubscription: false,
        });
      }
      this.setState({ isSignedIn: !!user });
    });
  };

  render() {
    let subscriptionCard;
    if (this.state.isPlanIdPresent && this.state.checkedSubscription && this.state.isLifeTime === false) {
      if (this.state.isAlreadySubscriber) {
        //If already a subscriber 1 Plan/Coupon
        subscriptionCard = (
          <Grid container justify="space-evenly" spacing={2}>
            <Grid container direction="column" justify="center">
              <Grid item>
                <Box pt={2}></Box>
              </Grid>
              <Grid item>
                <WhiteTextTypography variant="h5">Congrats!</WhiteTextTypography>
              </Grid>
              <Grid item>
                <Box pt={2}></Box>
              </Grid>
              <Grid item>
                <WhiteTextTypography variant="h5">Start Date : {userSubscriptionData.purchaseDate}</WhiteTextTypography>
              </Grid>
              <Grid item>
                <WhiteTextTypography variant="h5">Valid Until : {userSubscriptionData.expireDate}</WhiteTextTypography>
              </Grid>
              <Grid item>
                <Box pb={3}></Box>
              </Grid>
            </Grid>
          </Grid>
        );
        //If already a subscriber 1 end
      } else {
        //Subscription Purchase Screen
        subscriptionCard = <Subscription email={userData.email} planId={planId} couponId={couponId} userId={userData.userId} planData={planData} couponData={couponData} isPlanIdPresent={this.state.isPlanIdPresent} isCouponIdPresent={this.state.isCouponIdPresent} />;
      }
      //Plan ID and Check Subscription both true end
    } else if (this.state.isPromoted && this.state.checkedSubscription && this.state.isLifeTime === false) {
      if (this.state.isAlreadySubscriber) {
        //If already a subscriber 2 Promo
        subscriptionCard = (
          <Grid container justify="space-evenly" spacing={2}>
            <Grid container direction="column" justify="center">
              <Grid item>
                <Box pt={2}></Box>
              </Grid>
              <Grid item>
                <WhiteTextTypography variant="h5">Congrats!</WhiteTextTypography>
              </Grid>
              <Grid item>
                <Box pt={2}></Box>
              </Grid>
              <Grid item>
                <WhiteTextTypography variant="h5">Start Date : {userSubscriptionData.purchaseDate}</WhiteTextTypography>
              </Grid>
              <Grid item>
                <WhiteTextTypography variant="h5">Valid Until : {userSubscriptionData.expireDate}</WhiteTextTypography>
              </Grid>
              <Grid item>
                <Box pb={3}></Box>
              </Grid>
            </Grid>
          </Grid>
        );
        //If already a subscriber 2 end
      } else {
        subscriptionCard = (
          <Grid container direction="column" justify="center" spacing={2}>
            <Grid item>
              <Grid container justify="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    await this.forPromotion(userData);
                  }}
                >
                  <Typography variant="button">Activate Premium Access</Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        );
      }
    } else if (this.state.isBuyLifeTime && this.state.checkedSubscription && this.state.isLifeTime === false) {
      if (this.state.isAlreadySubscriber) {
        //If already a subscriber 1 Plan/Coupon
        subscriptionCard = (
          <Grid container justify="space-evenly" spacing={2}>
            <Grid container direction="column" justify="center">
              <Grid item>
                <Box pt={2}></Box>
              </Grid>
              <Grid item>
                <WhiteTextTypography variant="h5">Congrats!</WhiteTextTypography>
              </Grid>
              <Grid item>
                <Box pt={2}></Box>
              </Grid>
              <Grid item>
                <WhiteTextTypography variant="h5">Start Date : {userSubscriptionData.purchaseDate}</WhiteTextTypography>
              </Grid>
              <Grid item>
                <WhiteTextTypography variant="h5">Valid Until : {userSubscriptionData.expireDate}</WhiteTextTypography>
              </Grid>
              <Grid item>
                <Box pb={3}></Box>
              </Grid>
            </Grid>
          </Grid>
        );
      } else {
        //If Buy Life Time Subscription
        subscriptionCard = (
          <Grid container direction="column" justify="center" spacing={2}>
            <Grid item>
              <Grid container justify="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    await this.getCheckOutLink(userData);
                  }}
                >
                  <Typography variant="button">Buy Lifetime</Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        );
      }
    } else if (this.state.isLifeTime && this.state.checkedSubscription) {
      //If Life Time Subscription
      subscriptionCard = (
        <Grid container justify="space-evenly" spacing={2}>
          <Grid container direction="column" justify="center">
            <Grid item>
              <Box pt={2}></Box>
            </Grid>
            <Grid item>
              <WhiteTextTypography variant="h5">Congrats!</WhiteTextTypography>
            </Grid>
            <Grid item>
              <Box pt={2}></Box>
            </Grid>
            <Grid item>
              <WhiteTextTypography variant="h5">You have Lifetime access to Brightmind Plus.</WhiteTextTypography>
            </Grid>

            <Grid item>
              <Box pb={3}></Box>
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      // PlanID and Checked Subscription is not true
      console.log("this.state.checkedSubscription: ", this.state.checkedSubscription);
      console.log("this.state.isPlanIdPresent: ", this.state.isPlanIdPresent);
      subscriptionCard = (
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      );
    }

    return (
      <div className="App">
        {this.state.isSignedIn ? (
          <Box width="100%" height="100%" mx="auto" my="auto">
            {this.state.isLoading ? (
              <Grid container justify="center">
                <CircularProgress />
              </Grid>
            ) : (
              <Grid container direction="column" justify="center" alignItems="center">
                <Box py={1}></Box>
                <Container maxWidth="xs" component="main">
                  <Card>
                    <CardActionArea>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Name: {firebase.auth().currentUser.displayName ? firebase.auth().currentUser.displayName : this.state.nameFL}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          Email: {firebase.auth().currentUser.email}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  <Box py={2}>{subscriptionCard}</Box>
                </Container>
                <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={async () => {
                        userSubscriptionData = null;
                        isNewUser = false;
                        this.setState({
                          isPlanIdPresent: false,
                          isCouponIdPresent: false,
                          isAlreadySubscriber: false,
                          checkedSubscription: false,
                          isLoading: true,
                          isLifeTime: false,
                        });
                        await firebase.auth().signOut();
                        this.setState({
                          isLoading: true,
                        });
                      }}
                    >
                      <Typography variant="button">SIGN OUT</Typography>
                    </Button>
                  </Grid>

                  <Grid container justify="flex-start">
                    <Typography variant="caption">{version}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Box>
        ) : (
          <div>
            <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
            <Typography variant="caption"> {version}</Typography>
          </div>
        )}
      </div>
    );
  }
}

export default App;
